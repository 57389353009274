import { Layout } from '@msanvarov/core-components';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Skeleton,
  Dialog,
  Slider,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import SearchIcon from '@mui/icons-material/Search';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CodeIcon from '@mui/icons-material/Code';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useState } from 'react';
import getData, { getClickData } from '../utils/dataFetch';
import { useRouter } from 'next/router';
import ArticleHeaderBg from '../public/assets/background/job-header.png'; // Ensure this path is correct
import { setJobs, setUserPref, useAppDispatch, useAppSelector } from '@msanvarov/store';
import Image from 'next/image';
import Back from '../public/assets/icons/back.svg';

import AgricultureIcon from '@mui/icons-material/Agriculture';
// import CodeIcon from '@mui/icons-material/Code';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FactoryIcon from '@mui/icons-material/Factory';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ConstructionIcon from '@mui/icons-material/Construction';
import SchoolIcon from '@mui/icons-material/School';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import PublicIcon from '@mui/icons-material/Public';


import WorkOutlineIcon from '@mui/icons-material/WorkOutline'; // for Job Type
import CategoryIcon from '@mui/icons-material/Category'; // for Categories
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // for Salary
import InterestsIcon from '@mui/icons-material/Interests'; // for Areas of Interest
import SearchIcon from '@mui/icons-material/Search'; // for Keywords
import { useUser } from '@clerk/nextjs';
import Popup from './popup';
import DateToIST from '../utils/DateToIST';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const icons = {
  Agriculture: (props: any) => <AgricultureIcon {...props} />,
  SoftwareDevelopment: (props: any) => <CodeIcon {...props} />,
  'Mining&Minerals': (props: any) => <LocalGasStationIcon {...props} />,
  Utilities: (props: any) => <AccountTreeIcon {...props} />,
  Construction: (props: any) => <FactoryIcon {...props} />,
  Manufacturing: (props: any) => <FactoryIcon {...props} />,
  Wholesale_Trade: (props: any) => <AttachMoneyIcon {...props} />,
  Retail_Trade: (props: any) => <WarehouseIcon {...props} />,
  'Transportation&Warehousing': (props: any) => <AgricultureIcon {...props} />,
  Information: (props: any) => <AccountBalanceIcon {...props} />,
  'Finance&Insurance': (props: any) => <HomeWorkIcon {...props} />,
  'Real_Estate&Rental': (props: any) => <ConstructionIcon {...props} />,
  ProfessionalScientific: (props: any) => <AgricultureIcon {...props} />,
  ManagementofCompanies: (props: any) => <AgricultureIcon {...props} />,
  'Administrative&Support&Waste': (props: any) => (
    <AgricultureIcon {...props} />
  ),
  'Management&Remediation_Services': (props: any) => (
    <AgricultureIcon {...props} />
  ),
  Educational_Services: (props: any) => <AgricultureIcon {...props} />,
  'Health_Care&Social_Assistance': (props: any) => <SchoolIcon {...props} />,
  'Arts&Entertainment': (props: any) => <NotStartedIcon {...props} />,
  'Accommodation&Food_Services': (props: any) => (
    <LocalHospitalIcon {...props} />
  ),
  Other_Services: (props: any) => <FoodBankIcon {...props} />,
  Public_Administration: (props: any) => <PublicIcon {...props} />,
};

const Jobs = () => {
  const [jobData, setJobData] = useState([]);
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const sheet_name = 'jobs';
  const [showPopup , setShowPopup] = useState(true);
  const dispatch = useAppDispatch();

  const { value } = useAppSelector((state) => state.auth);

  console.log(value, 'values');
  const {isSignedIn , isLoaded , user} = useUser()
  const userpref  : any = useAppSelector((state) => state.userpref.userpref);



  useEffect(() => {

    if (!Boolean(Object.keys(value).length) && !user?.emailAddresses[0].emailAddress) {
      setShowPopup(true);
    }

    if(user?.emailAddresses[0].emailAddress){
      setShowPopup(false);
    }
  } , [user]);

  useEffect(() => {
    
    if(user?.firstName){
      console.log(user.primaryEmailAddress?.emailAddress);
      getClickData(user.primaryEmailAddress?.emailAddress).then((data) => {
        console.log(data);
        dispatch(setUserPref(data?.data))
    
      })
    }

  } , [user]);

  useEffect(() => {
    getData(sheet_name, null).then((res) => {
      setJobData(res?.data?.reverse());
      setLoading(false);
    });
  }, []);

  if (jobData) {
    dispatch(setJobs(jobData));
  }

  console.log(jobData);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if( isSignedIn && !user?.emailAddresses[0].emailAddress ){

    console.log(userpref , "userpreffff....");
    return <>
     <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Make it full-screen
              }}
            >
              <CircularProgress size={80} /> {/* Full-screen loader */}
      </Box>
    </>
  }

  console.log(userpref , 'userpreffff');

  return (
    <Layout>
      <section className="blog-area">
        <Container>
        {!user?.emailAddresses[0].emailAddress &&  <Popup onClose={() => setShowPopup(false)} />}
          <div
            className="blue-container"
            style={{
              backgroundColor: '#427BF1',
              backgroundImage: `url(${ArticleHeaderBg.src})`,
              backgroundSize: 'cover',
              color: 'white',
              padding: '20px',
              borderRadius: '20px',
              textAlign: 'center',
              marginBottom: '30px',
              position: 'relative',
              minHeight: '230px',
              height: 'auto',
            }}
          >
            <button
              onClick={() => router.back()}
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                padding: 0,
              }}
            >
              {/* <ArrowBackIcon style={{ color: 'white', fontSize: 30 }} /> */}
              <Image
                src={Back}
                alt="back"
                style={{ width: '54px', height: '54px' }}
              />
            </button>

            <div
              style={{
                position: 'relative',
                marginTop: '120px',
                width: '100%',
              }}
            >
              <input
                type="text"
                placeholder="Search Internships, remote or onsite, Job Category"
                onClick={handleClickOpen}
                style={{
                  padding: '10px 40px 10px 40px',
                  borderRadius: '65px',
                  border: 'none',
                  width: '100%',
                  fontSize: '16px',
                  paddingLeft: '40px',
                }}
              />
              <SearchIcon
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  color: 'black',
                  fontSize: 24,
                }}
              />
            </div>


<Dialog
  open={open}
  onClose={handleClose}
  maxWidth="md"
  fullWidth
  PaperProps={{
    style: {
      borderRadius: '20px', // Increased border radius for the dialog
    },
  }}
>
  <div style={{ padding: '40px' }}> {/* Increased padding */}
    
    {/* Keywords Filter */}
    <TextField
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SearchIcon style={{ marginRight: '8px' }} /> Keywords
        </div>
      }
      variant="outlined"
      fullWidth
      style={{ marginBottom: '20px' }}
    />

    {/* Job Type Filter */}
    <div style={{ marginBottom: '20px' }}>
      <h3 style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <WorkOutlineIcon style={{ marginRight: '8px' }} /> Job Type
      </h3>
      <FormControlLabel control={<Checkbox />} label="Full-Time" />
      <FormControlLabel control={<Checkbox />} label="Part-Time" />
      <FormControlLabel control={<Checkbox />} label="Internship" />
      <FormControlLabel control={<Checkbox />} label="Remote" />
    </div>

    {/* Areas of Interest Filter */}
    <div style={{ marginBottom: '20px' }}>
      <h3 style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <InterestsIcon style={{ marginRight: '8px' }} /> Areas of Interest
      </h3>
      <TextField label="Skills" variant="outlined" fullWidth />
    </div>

    {/* Salary Filter */}
    <div style={{ marginBottom: '20px' }}>
      <h3 style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <AttachMoneyIcon style={{ marginRight: '8px' }} /> Salary
      </h3>
      <Slider
        defaultValue={50000}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={1000}
        marks
        min={0}
        max={300000}
      />
    </div>

    {/* Categories Filter */}
    <div style={{ marginBottom: '20px' }}>
      <h3 style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <CategoryIcon style={{ marginRight: '8px' }} /> Categories
      </h3>
      <FormControlLabel control={<Checkbox />} label="Development & IT" />
      <FormControlLabel control={<Checkbox />} label="Design & Creative" />
      <FormControlLabel control={<Checkbox />} label="Sales & Marketing" />
      <FormControlLabel control={<Checkbox />} label="Management & Research" />
      <FormControlLabel control={<Checkbox />} label="Finance & Legal" />
      <FormControlLabel control={<Checkbox />} label="Human Resource" />
      <FormControlLabel control={<Checkbox />} label="Engineering" />
      <FormControlLabel control={<Checkbox />} label="Admin & Customer Support" />
      <FormControlLabel control={<Checkbox />} label="Content Writing & Translation" />
    </div>

    {/* View Results Button */}
    <Button
      variant="contained"
      style={{
        backgroundColor: 'black', // Button color set to black
        color: 'white', // Text color set to white
        fontWeight: 'bold',
        padding: '10px 20px',
      }}
      onClick={handleClose}
    >
      View Results
    </Button>
  </div>
</Dialog>



          </div>

          <Grid container spacing={2}>
            {loading
              ? Array.from(new Array(3)).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ borderRadius: 6 }}>
                      <CardContent>
                        <Skeleton
                          variant="rectangular"
                          width={40}
                          height={40}
                          sx={{ marginRight: 2 }}
                        />
                        <Skeleton variant="text" width="70%" height={30} />
                        <Skeleton
                          variant="text"
                          width="50%"
                          height={20}
                          sx={{ marginBottom: 2 }}
                        />
                        <Skeleton variant="text" width="80%" height={20} />
                        <Skeleton variant="text" width="60%" height={20} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              : jobData 
              
              &&
              
              <section className="ml-auto mr-auto" style={{width : "100%" , display : "flex" , flexDirection: "column", gap: "10px"}}>
                <section style={{width : "100%"}}>
                <span className='block text-xl text-bold m-[20px] border-b pl-[20px] pb-[10px] w-[95%] center-text '>{userpref?.jobs?.length ? "Your Applied Jobs" : null }</span>
                  <section className="w-full flex gap-2 justify-start flex-wrap ml-8 md:justify-center sm:justify-center center-align " style={{justifyContent : "flex-start"}}>
                    {Boolean(user?.emailAddresses[0].emailAddress) && jobData.filter((data : any ) => {
                      
                      if(userpref?.jobs?.length){

                        for(let pref of userpref.jobs){
                          console.log(pref);
                          // @ts-ignore 
                          if(pref.id === data.id){
                            return true
                          }
                        }

                      }

                      
                      return false;
                    
                    
                    }).map((jobpost: any) => {
                    //@ts-ignore
                    // const IconComponent: any =
                    // @ts-ignore
                      // icons[jobpost.company_type] || AccountCircleIcon;
                    return (
                      <section
                        key={jobpost.id}
                        className="max-w-[350px] min-h-[250px] m-[6px]"
                        onClick={() => {
                          router.push(`/job/${jobpost.id}`);
                        }}
                      >
                        <Card sx={{ borderRadius: 6, boxShadow: 0  , minHeight : "250px", minWidth : "350px"}}>
                          <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                              {/* <IconComponent
                                style={{
                                  color: getRandomColor(),
                                  fontSize: 40,
                                  marginRight: 16,
                                }}
                              /> */}
                              <Image
                            // @ts-ignore
                              src={jobpost.company_icon}
                              width={40}
                              height={40}
                              // @ts-ignore
                              alt={jobpost.company_name}
                              style={{ margin: '10px', borderRadius: '50%' }}
                            />
                              <Box>
                                <Typography
                                  variant="h6"
                                  component="div"
                                  fontWeight="bold"
                                >
                                  {jobpost.job_domain}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: '#596574' }}
                                >
                                  {jobpost.company_name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{ borderBottom: '1px dotted grey', mb: 2 }}
                            />
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              mt={1}
                            >
                              {jobpost.location}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              mt={1}
                              sx={{ color: '#006DDD', fontWeight: 'bold' }}
                            >
                              {jobpost.salary_range} / month
                            </Typography>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              mt={1}
                            >
                              <Typography variant="body2" color="text.secondary">
                                {jobpost.exp_reqd} years
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {DateToIST(String(jobpost.date))}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </section>
                    );
                  })}
                  </section>
                </section>
                <section className="ml-auto mr-auto" style={{width : "100%" , display : "flex " , flexDirection : "column" , gap : "10px"}}>
                  <span className='text-xl text-bold m-[20px] ml-[40px] border-b pb-[10px] center-text'>Jobs for you  </span>
                  <section className='' style={{ display : "flex" , flexDirection : "row" , gap: "6px" , flexWrap : "wrap" , justifyContent: "space-evenly"}}>
                    {jobData.filter((data : any ) => {

                      if(userpref?.jobs?.length){
                        for(let pref of userpref.jobs){
                          console.log(pref);
                          // @ts-ignore 
                          if(pref.id === data.id){
                            return false
                          }
                        }
                      }

                      return true;
                    }).map((jobpost: any) => {
                    // //@ts-ignore
                    // const IconComponent: any =
                    // // @ts-ignore
                    //   icons[jobpost.company_type] || AccountCircleIcon;
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                          maxWidth : "350px !important" ,
                          minHeight : "250px !important"
                        }}
                        key={jobpost.id}
                        onClick={() => {
                          router.push(`/job/${jobpost.id}`);
                        }}
                      >
                        <Card sx={{ borderRadius: 6, boxShadow: 0 , maxHeight: "300px" , minHeight : "250px !important"}}>
                          <CardContent>
                            <Box display="flex" alignItems="center" mb={2}>
                              {/* <IconComponent
                                style={{
                                  color: getRandomColor(),
                                  fontSize: 40,
                                  marginRight: 16,
                                }}
                              /> */}
                              <Image
                            // @ts-ignore
                              src={jobpost.company_icon}
                              width={40}
                              height={40}
                              // @ts-ignore
                              alt={jobpost.company_name}
                              style={{ margin: '10px', borderRadius: '50%' }}
                            />

                              <Box>
                                <Typography
                                  variant="h6"
                                  component="div"
                                  fontWeight="bold"
                                >
                                  {jobpost.job_domain}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: '#596574' }}
                                >
                                  {jobpost?.company_name}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{ borderBottom: '1px dotted grey', mb: 2 }}
                            />
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              mt={1}
                            >
                              {jobpost?.location}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.primary"
                              mt={1}
                              sx={{ color: '#006DDD', fontWeight: 'bold' }}
                            >
                              {jobpost?.salary_range} / month
                            </Typography>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              mt={1}
                            >
                              <Typography variant="body2" color="text.secondary">
                                {jobpost?.exp_reqd} years
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {DateToIST(String(jobpost?.date))}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                  </section>
                </section>
              </section>
              }
          </Grid>
        </Container>
      </section>
    </Layout>
  );
};

export default Jobs;
